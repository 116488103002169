import { Injectable } from "@angular/core";
import * as CryptoJS from "crypto-js";
import { environment as env } from '../../environments/environment';

@Injectable({
  providedIn: "root"
})
export class SeguridadDatos {
  constructor() {
  }

  key: string = env.decryptKey
  decryptEnv: string = env.decryptEnv
  decryptLS: string = env.decryptLS

  //Encripta la los datos
  public encrypt(str: string) {
    let encrypted = CryptoJS.AES.encrypt(str, this.key, {
      keySize: 16,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    //   console.log(str)
    return encrypted.toString();
  }

  private EncrypObj(obj: any) {
    const newObj: any = {};

    for (let keyName in obj) {
      if (obj[keyName] instanceof Array || obj[keyName] instanceof Object) {
        obj[keyName] = this.EncrypObj(obj[keyName]);
      }
      else newObj[keyName] = String(this.encrypt(obj[keyName]));
    }
    return newObj;
  }

  // Encripta los datos que que deben ser enviados en una peticion
  public EncrypDataHash(Datos: any) {
    return new Promise((resolve, reject) => {
      try {
        resolve(this.EncrypObj(Datos));
        // Object.entries(Datos).forEach(([keyOriginal, valueKey]: any ,index:number) => {
        //     var Tamano= Object.keys(Datos);
        //     if(typeof valueKey !="number" && valueKey !=""&& valueKey !=undefined && valueKey !=null){
        //         const Encrypt = this.encrypt(valueKey); //Encripto
        //         Datos[keyOriginal] = Encrypt;
        //     }
        //     if(index == Tamano.length-1){
        //         resolve(Datos)
        //     }
        // })
      } catch (error) {
        reject(error);
      }
    })
  }

  decrypt(str: string, decryptSource: string) {
    return CryptoJS.AES.decrypt(str, decryptSource, {
      keySize: 16,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    }).toString(CryptoJS.enc.Utf8)
  }

  DescrypV2(Datos: any) {
    let Data: any = CryptoJS.AES.decrypt(Datos.message, this.key, {
      keySize: 16,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    }).toString(CryptoJS.enc.Utf8);
    const TipoData = typeof Data
    switch (TipoData) {
      case "string":
        if (Data[0] === `[`) {
          const NewData: any = JSON.parse(`[${Data}]`)
          return JSON.parse(NewData)
        } else {
          return Data
        }
        break;
      default:
        return Data
        break;
    }
  }

  public DecryptDataHash = (sentence: string, encryptSource?: string) => {
    let originEncrypt: string
    !encryptSource || encryptSource == 'ENV' ? originEncrypt = this.decryptEnv : originEncrypt = this.decryptLS
    return new Promise((resolve, reject) => {
      try {
        resolve(this.decrypt(sentence, originEncrypt));
      } catch (error) {
        reject(error);
      }
    })
  }

  public encryptUniqueValue(str: string, encryptSource: string) {
    let encrypted = CryptoJS.AES.encrypt(str, encryptSource, {
      keySize: 16,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    //   console.log(str)
    return encrypted.toString();
  }

  public EncryptDataHashUniqueValue = (sentence: string, decryptSource?: string) => {
    let originDecrypt: string
    !decryptSource || decryptSource == 'ENV' ? originDecrypt = this.decryptEnv : originDecrypt = this.decryptLS
    return new Promise((resolve, reject) => {
      try {
        resolve(this.encryptUniqueValue(sentence, originDecrypt));
      } catch (error) {
        reject(error);
      }
    })
  }
}
