import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';

import { MenuController, NavController, Platform, ToastController } from '@ionic/angular';

import { StatusBar } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';

import { Storage } from '@ionic/storage';

import { UserData } from './providers/user-data';
import { SeguridadDatos } from './services/bscript.service';
import { environment } from '../environments/environment';
import axios from 'axios';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  appPages = [
    {
      title: 'Reportar avería',
      url: '/ReportarAveria',
      icon: 'construct'
    },
    {
      title: 'Lista de avería',
      url: '/ListAveria',
      icon: 'document-text'
    },
  ];
  loggedIn = false;
  dark = false;

  DataUser: any = []

  constructor(
    private menu: MenuController,
    private platform: Platform,
    private router: Router,
    private storage: Storage,
    private userData: UserData,
    private swUpdate: SwUpdate,
    private toastCtrl: ToastController,
    public navCtrl: NavController,
    private _enc: SeguridadDatos
  ) {
    this.initializeApp();
  }

  async ngOnInit() {
    this.checkVersion();
    this.checkLoginStatus();
    this.listenForLoginEvents();

    this.swUpdate.available.subscribe(async res => {
      const toast = await this.toastCtrl.create({
        message: 'Update available!',
        position: 'bottom',
        buttons: [
          {
            role: 'cancel',
            text: 'Reload'
          }
        ]
      });

      await toast.present();

      toast
        .onDidDismiss()
        .then(() => this.swUpdate.activateUpdate())
        .then(() => window.location.reload());
    });

    this.DataUser = JSON.parse(localStorage.getItem('User'))

    if (this.DataUser) {
      this.navCtrl.navigateForward("ReportarAveria");
    } else {
      this.navCtrl.navigateForward("login");
    }

  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (this.platform.is('hybrid')) {
        StatusBar.hide();
        SplashScreen.hide();
      }
    });
  }

  checkLoginStatus() {
    return this.userData.isLoggedIn().then(loggedIn => {
      return this.updateLoggedInStatus(loggedIn);
    });
  }

  updateLoggedInStatus(loggedIn: boolean) {
    setTimeout(() => {
      this.loggedIn = loggedIn;
    }, 300);
  }

  listenForLoginEvents() {
    window.addEventListener('user:login', () => {
      this.updateLoggedInStatus(true);
    });

    window.addEventListener('user:signup', () => {
      this.updateLoggedInStatus(true);
    });

    window.addEventListener('user:logout', () => {
      this.updateLoggedInStatus(false);
    });
  }

  logout() {
    this.userData.logout().then(() => {
      return this.router.navigateByUrl('/app/tabs/schedule');
    });
  }

  openTutorial() {
    this.menu.enable(false);
    this.storage.set('ion_did_tutorial', false);
    this.router.navigateByUrl('/tutorial');
  }

  CloseSession() {
    localStorage.clear();
    this.navCtrl.navigateForward("login");
  }

  /**
   * Permite validar si la versión actual de la aplicación son compatibles
   */
  public checkVersion() {
    const headers = {
      directory: this._enc.encrypt("Consultas"),
      method: this._enc.encrypt("ControlVAveriaGeneral"),
      token: this._enc.encrypt(environment.ApiKeyApissl),
      platform: this._enc.encrypt("Reporte"),
      cedula: this._enc.encrypt("12345678"),
      version: this._enc.encrypt(environment.version)
    }
    
    this._enc.DecryptDataHash(environment.urlApiTLS)
      .then(url => {
        url = "http://localhost:9009";
        axios.get(url as string, { headers })
          .then((res: any) => {
            const data = this._enc.decrypt(res.data.message, this._enc.key);

            const message = JSON.parse(data);
            
            if(message.url) {
              console.log("¡Actualización necesaria!");
              const dateTimestamp = Date.now() + 5000;

              try {
                const value = localStorage.getItem("timestamp_prevent_update");
                const numberValue = Number(value);

                if(value && !Number.isNaN(numberValue)) {
                  if(Date.now() < numberValue) {
                    // Prevenir para no actualizar
                    return;
                  }
                }
              }
              catch(err) {
                console.log("No es necesario prevenir la actualización");
              }
              
              Swal.fire({
                icon: "info",
                title: "Actualización",
                text: "Hemos determinado que esta aplicación se encuentra desactualizada.",
                footer: "En breve se actualizará a una nueva versión",
                allowEnterKey: false,
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false
              });

              // Actualización necesaria
              caches.keys().then((cachesNames) => {
                const refreshPage = () => {
                  const relativeMiliseconds = dateTimestamp - Date.now();

                  const update = () => {
                    // localStorage.setItem("timestamp_prevent_update", String(Date.now() + 10000));
                    localStorage.setItem("timestamp_prevent_update", String(Date.now() + 1.2e+6));
                    document.location.reload();
                  }
                  
                  if(relativeMiliseconds > 0) {
                    setTimeout(update, relativeMiliseconds);
                  }
                  else {
                    update();
                  }
                }
                
                Promise.all(cachesNames.map(name => caches.delete(name)))
                  .then(refreshPage)
                  .catch(refreshPage)
              })
              // caches.keys().then(function(cacheNames) {
              //   cacheNames.forEach(function(cacheName) {
              //     caches.delete(cacheName);
              //   });
              // });
            }
            else {
              console.log("¡Ultima versión detectada!");
            }
          })
          .catch((err) => {
            console.log(err);
          })
      })
      .catch(err => {
        console.log(err);
      })
  }
  
}
