// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
export const environment = {
  production: false,

  apiPhP: 'https://crm.thomas-talk.me/api/staff/?',
  ApiThomas: 'https://api.thomas-talk.me/',
  ApiBD:'https://backend.thomas-talk.me/api/v1/',
  NameBD:'thomas_cobertura',
  // serverAverias: "http:localhost:9014",
  decryptKey: 'T0rNaDoK4tr1Na?RTgcNmhKU=',
  decryptEnv: 'EnvironmentAaanfaion0501faoi==',
  decryptLS: 'LocalStorageAaanfaion0501faoi==',
  ApiKeyApissl: '4p1_[51t3m4s]_f186@th0m4s',
  urlApiTLS: "U2FsdGVkX1/Axy7GvMSn4Rs8j4ZFk85P57+4ZiVdNuVaa77C7KRWW4TZ0cNj6xkX",//"U2FsdGVkX18pq04gEPDRxMB43nS/1oPqJRBKX30L+Gsu6rq25aZzsGe1YN21jyVS"
  version: "V1.0.0",
  serverAverias: "https://apiolt.thomas-talk.me"
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.